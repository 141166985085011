import React, { useState, useCallback, memo } from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { styles } from "../styles";
import { services } from "../constants";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant } from "../utils/motion";

const ServiceCard = memo(({ index, title, icon, dis }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      className="xs:w-[250px] w-full cursor-pointer"
    >
      <motion.div
        className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card"
        whileHover={{
          scale: 1.05,
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          transition: { duration: 0.3 },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered ? (
          <motion.div
            className="bg-tertiary rounded-[20px] p-8 min-h-[280px] flex justify-center items-center flex-col overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <p className="text-white text-[20px] font-bold text-center select-none">
              {dis}
            </p>
          </motion.div>
        ) : (
          <Tilt
            options={{
              max: 25,
              scale: 1.02,
              speed: 350,
            }}
            className="bg-tertiary rounded-[20px] p-8 min-h-[280px] flex justify-center items-center flex-col overflow-hidden"
          >
            <motion.img
              src={icon}
              alt={`${title} icon`}
              className="w-16 h-16 object-contain mb-4 ico-fun-about"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            />
            <motion.h3
              className="text-white text-[20px] font-bold text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              {title}
            </motion.h3>
          </Tilt>
        )}
      </motion.div>
    </motion.div>
  );
});

ServiceCard.displayName = "ServiceCard";

const About = () => (
  <>
    <motion.div variants={textVariant()}>
      <p className={styles.sectionSubText}>Welcome to the Journey</p>
      <h2 className={`${styles.sectionHeadText} magic-tex`}>
        Explore the Overview
      </h2>
    </motion.div>
    <motion.p
      variants={fadeIn("", "", 0.1, 1)}
      className="mt-4 text-secondary-text text-[16px]  leading-[26px] font-light"
    >
      Hey there! 👋 I'm a passionate software engineer with a bachelor's degree
      in the field. As a versatile full-stack developer, I'm experienced in
      various programming languages and frameworks. My expertise spans both
      front-end and back-end technologies, allowing me to create comprehensive,
      efficient, and user-friendly solutions. While I have a strong background
      in web development, I'm always eager to explore new technologies and adapt
      to different domains. My solid foundation in software engineering
      principles enables me to quickly learn and apply new skills. Whether it's
      crafting intuitive user interfaces, optimizing backend systems, or diving
      into emerging tech, I'm ready to take on diverse challenges. Let's
      collaborate to bring your ideas to life and create scalable, innovative
      solutions. Ready for the journey? 🚀
    </motion.p>
    <div className="mt-20 flex flex-wrap gap-10 justify-center">
      {services.map((service, index) => (
        <ServiceCard key={service.title} index={index} {...service} />
      ))}
    </div>
  </>
);

export default SectionWrapper(About, "about");
