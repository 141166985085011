import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ReactGA from "react-ga4";
import { Navbar, Hero } from "./components";

const About = lazy(() => import("./components/About"));
const Contact = lazy(() => import("./components/Contact"));
const Experience = lazy(() => import("./components/Experience"));
const Feedbacks = lazy(() => import("./components/Feedbacks"));
const Tech = lazy(() => import("./components/Tech"));
const Works = lazy(() => import("./components/Works"));
const StarsCanvas = lazy(() => import("./components/canvas/Stars"));
const ScrollButton = lazy(() => import("./components/ScrollButton"));
const Security = lazy(() => import("./components/Security"));
const Footer = lazy(() => import("./components/Footer"));
const Preloader = lazy(() => import("./components/Preloader/Preloader"));

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const preloaderRef = useRef(null);

  useEffect(() => {
    const initializeAnalyticsAndScroll = async () => {
      ReactGA.initialize("G-3SWFM2G8KG");
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });

      const LocomotiveScroll = (await import("locomotive-scroll")).default;
      new LocomotiveScroll();

      setTimeout(() => {
        setIsLoading(false);
        document.body.style.cursor = "default";
        window.scrollTo(0, 0);
      }, 2000);
    };

    initializeAnalyticsAndScroll();
  }, []);

  return (
    <BrowserRouter>
      <Analytics />
      <AnimatePresence mode="sync">
        {isLoading ? (
          <Preloader key="preloader" ref={preloaderRef} />
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <div className="relative z-0 bg-[#040015]" key="main-content">
              <Navbar />
              <Hero />
              <StarsCanvas />
              <About />
              <Experience />
              <Tech />
              <Security />
              <Works />
              <Feedbacks />
              <Contact />
              <Footer />
            </div>
          </Suspense>
        )}
      </AnimatePresence>
      <ScrollButton />
    </BrowserRouter>
  );
};

export default App;
