import React, { useEffect, useState, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import { styles } from "../styles";
import { textVariant } from "../utils/motion";
import { testimonials } from "../constants";
import { SectionWrapper } from "../hoc";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const TestimonialCard = React.memo(
  ({ testimonial, name, designation, company, image }) => (
    <motion.div className="bg-black-200 p-4 rounded-3xl max-w-[90%] cursor-grab">
      <div className="mt-1">
        <p className="text-white tracking-wider text-[15px] xs:text-[18px]">
          {testimonial}
        </p>
        <div className="mt-7 flex justify-between items-center gap-1">
          <div className="flex-1 flex flex-col">
            <p className="text-white font-medium text-base">
              <span className="blue-text-gradient">@</span> {name}
            </p>
            <p className="mt-1 text-secondary text-xs">
              {designation} of {company}
            </p>
          </div>
          <img
            src={image}
            alt={`feedback by ${name}`}
            className="w-10 h-10 rounded-full object-cover"
            loading="lazy"
          />
        </div>
      </div>
    </motion.div>
  )
);

TestimonialCard.displayName = "TestimonialCard";

const TestimonialCarousel = () => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const swiperConfig = useMemo(
    () => ({
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: true,
      },
      speed: 9000,
      modules: [Autoplay],
      className: "max-w-full",
      breakpoints: {
        640: { slidesPerView: windowWidth > 1027 ? 3 : 1 },
        768: { slidesPerView: windowWidth > 1027 ? 3 : 1 },
        1024: { slidesPerView: windowWidth > 1027 ? 3 : 1 },
      },
    }),
    [windowWidth]
  );

  const renderTestimonials = useCallback(
    () =>
      testimonials.map((testimonial, index) => (
        <SwiperSlide key={index} className="flex gap-5">
          <TestimonialCard {...testimonial} />
        </SwiperSlide>
      )),
    []
  );

  return (
    <div className="mt-12 bg-black-100 rounded-[20px] overflow-hidden">
      <div className="bg-tertiary rounded-2xl px-6 py-10 min-h-[190px] xs:min-h-[250px]">
        <motion.div variants={textVariant()}>
          <p className="sm:text-base text-sm text-secondary uppercase tracking-wider">
            What others say
          </p>
          <h2 className="text-white font-bold md:text-4xl xs:text-2xl text-xl magic-tex">
            Testimonials.
          </h2>
        </motion.div>
      </div>

      <div
        className={`-mt-20 pb-14 ${
          windowWidth >= 1027 ? styles.paddingX : ""
        } flex gap-7`}
      >
        <Swiper {...swiperConfig}>
          <div className="-mt-20 flex card-rec min-w-max gap-7">
            {renderTestimonials()}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default SectionWrapper(TestimonialCarousel, "testimonials");
