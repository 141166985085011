import React, { memo } from "react";
import { motion } from "framer-motion";
import { textVariant } from "../utils/motion";
import { Securty1, Securty2, SecurtyVudo } from "../assets";

const SecurityIcon = memo(() => (
  <div className="w-auto h-auto flex flex-col group items-center cursor-pointer">
    <img
      className="w-[50px] translate-y-5 transition-all duration-200 group-hover:translate-y-11"
      src={Securty2}
      width={50}
      height={50}
      alt="Security Icon Top"
    />
    <img
      className="w-[70px] z-10"
      src={Securty1}
      width={70}
      height={70}
      alt="Security Icon Bottom"
    />
  </div>
));

SecurityIcon.displayName = "SecurityIcon";

const EncryptionBox = memo(() => (
  <motion.div
    variants={textVariant(2)}
    className="px-4 py-1 border my-5 border-[#7042f88b] opacity-90 rounded-full"
  >
    <div className="text-xs sm:text-sm md:text-base">Encryption</div>
  </motion.div>
));

EncryptionBox.displayName = "EncryptionBox";

const SecurityDescription = memo(() => (
  <motion.div
    variants={textVariant(3)}
    className="security-descr text-center text-gray-300 flex flex-col"
  >
    <span>The contents of your notes are end-to-end encrypted.</span>
    <span>No one else can read them (not even us).</span>
  </motion.div>
));

SecurityDescription.displayName = "SecurityDescription";

const Security = () => {
  return (
         <div className="select-none flex flex-row flex-wrap justify-center gap-10 justify-items-center relative">
      <div className="w-auto h-auto absolute  translate-y-[270px] flex flex-col items-center justify-center">
        <div className="w-auto h-auto flex   flex-col group items-center cursor-pointer  ">
          <div className=" relative top-[-140px]">
            <motion.h2
              variants={textVariant(1)}
              className=" text-[40px]  500:text-[45px] font-medium 500:font-semibold text-center  text-gray-200 mb-[10px] mt-[-50px] sm:mt-0"
            >
              Performent{" "}
              <span className="magic text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500 magic-tex">
                &
              </span>{" "}
              security
            </motion.h2>
          </div>
        </div>

        <SecurityIcon />
        <EncryptionBox />

        <div className="relative top-[140px]">
          <SecurityDescription />
        </div>
      </div>

      <div className=" w-[1500px]  h-[850px] z-[-10]  flex flex-row   items-start justify-center ">
        <a href="#video2" aria-label="Security Video Navigation">
          <video
            className="fixed-size-video"
            preload="none"
            playsInline
            loop
            muted
            autoPlay
            src={SecurtyVudo}
          >
            Your browser does not support the video tag.
          </video>
        </a>
      </div>
    </div>
  );
};

export default memo(Security);
