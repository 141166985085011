import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import { SectionWrapper } from "../hoc";
import { technologies } from "../constants";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";

// Lazy load the BallCanvas component
const BallCanvas = lazy(() => import("../components/canvas/Ball"));

const Tech = () => {
  const [displayedTechnologies, setDisplayedTechnologies] = useState([]);

  const filteredTechnologies = useMemo(() => {
    return window.innerWidth > 768 ? technologies : technologies.slice(0, 6);
  }, []);

  const addTechnology = useCallback((technology) => {
    setDisplayedTechnologies((prev) => [...prev, technology]);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const timers = [];

    setDisplayedTechnologies([]);

    filteredTechnologies.forEach((technology, index) => {
      const timer = setTimeout(() => {
        if (isMounted) {
          addTechnology(technology);
        }
      }, index * 500);
      timers.push(timer);
    });

    return () => {
      isMounted = false;
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [filteredTechnologies, addTechnology]);

  return (
    <>
      <motion.div
        className="w-full flex flex-col justify-center items-center p-8 mr-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          id="Wecom-box"
          className="animate-slidedown12 inline-flex items-center px-4 py-3 border border-[#7042f88b] rounded-md bg-opacity-20 bg-purple-900 hover:bg-opacity-30 transition-all duration-300"
        >
          <SparklesIcon className="h-5 w-5 text-[#b49bff] mr-2" />
          <span id="Wecom-text" className="text-sm 310:text-xs 370:text-sm">
            Experience With Some Technology
          </span>
        </div>
      </motion.div>
      <div className="flex flex-row flex-wrap justify-center gap-10">
        {displayedTechnologies.map((technology) => (
          <motion.div
            className="w-28 h-28 ball-canvas cursor-grab"
            key={technology.name}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Suspense
              fallback={
                <div className="w-full h-full flex items-center justify-center">
                  Loading...
                </div>
              }
            >
              <BallCanvas
                className="w-full h-full cursor-grab"
                icon={technology.icon}
              />
            </Suspense>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(Tech, "tech");
