import {
  mobile,
  backend,
  creator,
  web,
  git,
  docker,
  slotgame,
  carrent,
  jobit,
  tripguide,
  python,
  ShahrzadRestaurant,
  Movix,
  Khalid,
  Jwad,
  Hlas,
  Rashed,
  Karam,
  Sultan,
  typescrip,
  scss,
  angular,
  Hasura,
  dotnet,
  postgresql,
  linux,
  csharp,
  graphql,
  AhliBank,
  azure,
  capellasolutions,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Problem Solver",
    icon: web,
    dis: "Skilled in analytical thinking and creative problem-solving, able to tackle complex issues.",
  },
  {
    title: "Frontend Wizard",
    icon: backend,
    dis: "Mastery in front-end technologies, delivering engaging and interactive user interfaces.",
  },
  {
    title: "Backend Maestro",
    icon: creator,
    dis: "Specializing in server-side development, adept at crafting robust and scalable backend architectures.",
  },
  {
    title: "Code Artisan",
    icon: mobile,
    dis: "Dedicated to writing clean, maintainable code that aligns with best practices and coding standards",
  },
];

const technologies = [
  {
    name: "typescrip",
    icon: typescrip,
  },
  {
    name: "angular",
    icon: angular,
  },
  {
    name: "csharp",
    icon: csharp,
  },
  {
    name: "dotnet",
    icon: dotnet,
  },
  {
    name: "postgresql",
    icon: postgresql,
  },

  {
    name: "graphql",
    icon: graphql,
  },
  {
    name: "Hasura",
    icon: Hasura,
  },
  {
    name: "azure",
    icon: azure,
  },
  {
    name: "docker",
    icon: docker,
  },
  {
    name: "linux",
    icon: linux,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "Python",
    icon: python,
  },
  {
    name: "scss",
    icon: scss,
  },
];

const experiences = [
  {
    title: "Software Engineering",
    company_name: "Capellasolutions",
    icon: capellasolutions,
    iconBg: "#383E56",
    date: "August 2023",
    points: [
      "Specializing in both front-end and back-end technologies, I contribute to the entire software development life cycle.",
      "Responsible for designing and implementing scalable architectures, ensuring the seamless integration of front-end and back-end components.",
      "Successfully delivered high-impact projects, optimizing application performance and enhancing user experience.",
      "Collaborated with cross-functional teams to implement DevOps best practices, fostering a culture of continuous improvement.",
    ],
  },
  {
    title: "Internship",
    company_name: "Jordan Ahli Bank",
    icon: AhliBank,
    iconBg: "#383E56",
    date: "Oct 2022 - Jan 2023",
    points: [
      "Developed proficiency in Python programming, including data structures, algorithms, and best coding practices.",
      "Mastered database concepts and gained practical skills in database design, implementation, and optimization.",
      "Applied acquired knowledge to analyze and solve complex problems.",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "Jaber is not just a colleague; he's an invaluable member of our team. His innovative approach to coding and collaborative spirit significantly elevate our projects. ",
    name: "Senior Software Engineer",
    designation: "Jawad Samawi",
    company: "Capella Solutions",
    image: Jwad,
  },
  {
    testimonial:
      "I wholeheartedly recommend Jaber for their exceptional leadership skills and outstanding contributions to our university community. ",
    name: "Hala Abdelrahman",
    designation: "Quality Assurance Engineer",
    company: "Capella Solutions",
    image: Hlas,
  },
  {
    testimonial:
      "Jaber is a brilliant Full Stack Software Engineer. His technical prowess and problem-solving skills have been crucial to our team's success. An asset to any project!",
    name: "Rasheed Rabata",
    designation: "Founder",
    company: "Capella Solutions",
    image: Rashed,
  },
  {
    testimonial:
      "I am delighted to recommend Jaber for their exceptional academic prowess, strong work ethic, and unwavering dedication to learning. ",
    name: "Khalid Alkharabsheh",
    designation: "APSE",
    company: " AL balqa' Applied University",
    image: Khalid,
  },
  {
    testimonial:
      "I wholeheartedly recommend Jaber for their exceptional leadership skills and outstanding contributions to our university community. ",
    name: "Sultan Al Khatib",
    designation: "APSE",
    company: " AL balqa' Applied University",
    image: Sultan,
  },
  {
    testimonial:
      "I confidently recommend Jaber for their exceptional research capabilities and their commitment to pushing the boundaries of knowledge.",
    name: "Karam Mustafa",
    designation: "APSE",
    company: " AL balqa' Applied University",
    image: Karam,
  },
];

const projects = [
  {
    name: "Car Rent",
    description:
      "Web-based platform that allows users to search, book, and manage car rentals from various providers, providing a convenient and efficient solution for transportation needs.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: carrent,
    source_code_link: "https://github.com/",
  },
  {
    name: "Job IT",
    description:
      "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "restapi",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: jobit,
    source_code_link: "https://github.com/",
  },
  {
    name: "Trip Guide",
    description:
      "A comprehensive travel booking platform that allows users to book flights, hotels, and rental cars, and offers curated recommendations for popular destinations.",
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: tripguide,
    source_code_link: "https://github.com/",
  },
  {
    name: "Shahrzad Restaurant",
    description:
      "Prepare your taste buds for a culinary adventure like no other with Shahrzad, the ultimate restaurant web page that brings a unique shopping experience right to your screen.  Explore a world of delectable dishes, tantalizing flavors, and top-notch services, all in one convenient platform.",
    tags: [
      {
        name: "React JS",
        color: "blue-text-gradient",
      },
      {
        name: "Firebase",
        color: "green-text-gradient",
      },
      {
        name: "Tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: ShahrzadRestaurant,
    source_code_link: "https://github.com/Jaber-Saed/Progect-food",
  },
  {
    name: "Movix",
    description:
      "Get ready for an immersive movie-watching experience like no other with Movix! Our cutting-edge webpage brings the world of cinema to your screen, giving you instant access to a vast library of movies at your fingertips.Plus, our seamless streaming technology ensures smooth playback and high-quality video.",
    tags: [
      {
        name: "React JS",
        color: "blue-text-gradient",
      },
      {
        name: "Axios",
        color: "pink-text-gradient",
      },
    ],
    image: Movix,
    source_code_link: "https://github.com/Jaber-Saed/Movix-Single-Page",
  },
  {
    name: "SpinMaster",
    description:
      "Get ready for a thrilling slot game experience like no other with SpinMaster!  Our innovative web page brings the excitement and charm of a casino right to your screen, allowing you to spin the reels and win big from the comfort of your own home.you can enjoy a slot games, each with its unique theme, stunning graphics, and immersive sound effects",
    tags: [
      {
        name: "HTML",
        color: "blue-text-gradient",
      },
      {
        name: "Css ",
        color: "green-text-gradient",
      },
      {
        name: "Java Script",
        color: "pink-text-gradient",
      },
    ],
    image: slotgame,
    source_code_link: "https://github.com/Jaber-Saed/slot-game",
  },
];

export { services, technologies, experiences, testimonials, projects };
