import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { navLinks } from "../constants";
import { logo, menu, close, navVudio } from "../assets";

const Navbar = () => {
  const [active, setActive] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="w-full h-[940px] shadow-lg shadow-[#030014]/50 absolute bg-cover flex flex-col overflow-hidden items-center justify-start px-[15px]">
        <div className="w-[1250px] h-full z-[-10] absolute top-[-280px] flex flex-row items-start justify-center">
          <video
            className="w-full h-auto rotate-180"
            preload="false"
            playsInline
            loop
            muted
            autoPlay
            src={navVudio}
          />
        </div>
      </div>
      <nav
        className={`w-full h-[65px] fixed shadow-lg shadow-[#2A0E61]/50 bg-[#03001417] backdrop-blur-md z-50 ${
          isScrolled ? "scrolled" : ""
        }`}
      >
        <div className="w-full max-w-[1232px] h-full flex items-center justify-between m-auto px-[10px] sm:px-[20px] md:px-[40px] xl:px-0">
          <div className="animate-slidedown w-full flex justify-between items-center">
            <Link
              to="/"
              className="flex items-center gap-1 text-slate-100 transition duration-300"
              onClick={() => {
                setActive("");
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={logo}
                alt="logo"
                className="w-14 h-14 object-contain cursor-pointer logo-hover"
              />
              <div className="flex flex-col">
                <p className="text-[18px] font-bold cursor-pointer">Jaber</p>
                <span className="hidden sm:block text-xs">
                  Software Engineering
                </span>
              </div>
            </Link>
            <ul className="hidden md:flex gap-10">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`text-[18px] font-medium cursor-pointer ${
                    active === nav.title ? "text-slate-100" : "text-secondary"
                  } hover:text-white transition duration-300`}
                  onClick={() => setActive(nav.title)}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
            </ul>
            <div className="md:hidden">
              <img
                src={isMenuOpen ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain cursor-pointer"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              {isMenuOpen && (
                <div className="absolute top-[56px] right-0 mx-2 my-2 min-w-[140px] rounded-xl bg-black-gradient p-6 black-gradient">
                  <ul className="flex flex-col gap-4">
                    {navLinks.map((nav) => (
                      <li
                        key={nav.id}
                        className={`font-medium cursor-pointer text-[16px] ${
                          active === nav.title ? "text-white" : "text-secondary"
                        }`}
                        onClick={() => {
                          setIsMenuOpen(false);
                          setActive(nav.title);
                        }}
                      >
                        <a href={`#${nav.id}`}>{nav.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;


console.log(
  " I'm the best.  No cap. \n" +
    "\n cup of coffee !" +
    "\n Check out here if you want to work with me: jaberthic@gmail.com \n" +
    "\n      /$$$$$           /$$                                  /$$$$$$            /$$       /$$ " +
    "\n     |__  $$          | $$                                 /$$__  $$          |__/      | $$ " +
    "\n        | $$  /$$$$$$ | $$$$$$$   /$$$$$$   /$$$$$$       | $$  \\__/  /$$$$$$  /$$  /$$$$$$$ " +
    "\n        | $$ |____  $$| $$__  $$ /$$__  $$ /$$__  $$      |  $$$$$$  |____  $$| $$ /$$__  $$ " +
    "\n   /$$  | $$  /$$$$$$$| $$  \\ $$| $$$$$$$$| $$  \\__/       \\____  $$  /$$$$$$$| $$| $$  | $$ " +
    "\n  | $$  | $$ /$$__  $$| $$  | $$| $$_____/| $$             /$$  \\ $$ /$$__  $$| $$| $$  | $$ " +
    "\n  |  $$$$$$/|  $$$$$$$| $$$$$$$/|  $$$$$$$| $$            |  $$$$$$/|  $$$$$$$| $$|  $$$$$$$ " +
    "\n   \\______/  \\_______/|_______/  \\_______/|__/             \\______/  \\_______/|__/ \\_______/ " +
    "\n"
);

